import React from "react";
import "./tools.css";

const Tools = () => {
  return (
    <>
      <div id="social-media">
        <a
          href="https://www.facebook.com/isma3iltata/"
          target={"blanck"}
          title="contact me on Facebook"
        >
          <img src="img/facebook.svg" alt="" />
        </a>
        <a href="https://www.linkedin.com/in/ismail-ait-hsaine-557840244/" target={"blanck"} title="contact me on Linkedin">
          <img src="img/linkedin.svg" alt="" />
        </a>
        <a
          href="https://www.instagram.com/ismailaithsaine/"
          target={"blanck"}
          title="contact me on Instagram"
        >
          <img src="img/instagram.svg" alt="" />
        </a>
        <a
          href="mailto:aithsaine3@gmail.com"
          target={"blanck"}
          title="contact me on Gmail"
        >
          <img src="img/gmail.svg" alt="" />
        </a>
        <a href="#" target={"blanck"} title="contact me on Twitter">
          <img src="img/twitter.svg" alt="" />
        </a>
        <div className=""></div>
      </div>
  
    </>
  );
};
export default Tools;
