import React from "react";
import "./projects.css"
import school_video from "../assets/school-project.mp4"
import rabsarl_video from "../assets/rabsarl-project.mp4"

export default function Projects() {
    return <section id="projects" >
        <h1 className="text-white"><span className="h1 big-title">&#60;/&#62;</span> My Projects :</h1>
        <hr className="text-white" />
        <div id="content" className="d-flex flex-column" >
            <hr className="text-white" />
            <h6 className="text-white text-center mt-5">1- School management system</h6>

            <div className="item">
                <video controls autoPlay>
                    <source src={school_video} type="video/mp4" />
                </video>
                <div>
                    {/* <p className="text-x">i build this application using react and laravel APIs</p> */}
                    <ol>
                        <li>generate Students</li>
                        <li>generate teachers</li>
                        <li>generate notes</li>
                        <li>modules/ groups/ choices</li>
                        <li>generate exams</li>
                        <li>statistics</li>
                        <li>Export/Import</li>
                    </ol>
                    <a target="_blank" href="https://github.com/aithsaine/school-management-system" class="badge badge-dark">view code source</a>
                </div>
            </div>
            <hr className="text-white" />

            <h6 className="text-white text-center mt-4">2- Manage a Construction Products Business</h6>

            <div className="item ">
                <video controls autoPlay>
                    <source src={rabsarl_video} type="video/mp4" />
                </video>
                <div>
                    {/* <p className="text-x">i build this application using react and laravel APIs</p> */}
                    <p>As Freelancer i build an application for this company to manage his clients and commandes and to facilite calculate his turnover for each month and to secure his data.</p>
                    <a target="_blank" href="https://github.com/aithsaine/Entreprise-des-Produits-de-construction" class="badge badge-dark">view code source</a>
                </div>
            </div>

        </div>
    </section>
}