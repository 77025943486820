import "./App.css";
import Header from "./components/header/header";
import Tools from "./components/body-tools/tools";
import About from "./components/about-me/about";
import Welcome from "./components/welcome/welcome";
import Skills from "./components/skills/skills";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Projects from "./components/projects/projects";
function App() {
  return (
    <>
      <Header />
      <Tools />
      <Welcome />
      <About />
      <Skills />
      <Projects/>
      {/*   <BrowserRouter>
                  <Routes>
                        <Route exact path='/contact-me' element={<ContactMe/>}/>
                 </Routes>
            </BrowserRouter> */}
    </>
  );
}

export default App;
