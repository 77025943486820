import React from "react";
import "./welcome.css"
const Welcome = () => {
    return (
        <section id="welcome-section" >
            <h6 id="-title">Hi, my name is</h6>
            <h2 id="name">ISMAIL AIT HSAINE</h2>
            <h5 className="text my-4">I'm a passionate <span id="opt-dev">Full-stack Developer</span>  with two years of professional experience. I love crafting innovative and user-friendly solutions that meet the needs of modern web applications. In this presentation, I'll take you through my journey as a fullstack developer, showcasing my skills, projects, and achievements along the way.
                improve my personal techniks .</h5>
            <h5 className="text my-4">Moreoever I know also generating a relational an non relational databases and play with data.</h5>
            <a href="https://drive.usercontent.google.com/download?id=1-lhkgdMff_wXJKQc_LM6RqFw629JDY3u&export=download&authuser=0&confirm=t&uuid=98f4557b-c98b-48f7-8fd9-bfd89c0bbf80&at=APZUnTUjRZ_BTOnhk8yCpz9xpCS2:1710804380190" className="btn-lg btn-outline-info " id="download-resume">Download My Resume</a>
        </section>
    )
}

export default Welcome;