import React, { Component } from "react";
import "./header.css";
import "./contact-me.css";
import { BrowserRouter, NavLink } from "react-router-dom";
import $ from "jquery";
import emailjs from "@emailjs/browser";

class Header extends Component {
  state = {
    toggle: false,
    name: "empty",
    email: "empty",
    msg: "empty",
  };

  sendEmail = () => {
    if (
      this.state.email === "empty" ||
      this.state.name === "empty" ||
      this.state.msg === "empty"
    ) {
      alert("fill all informations!");
    } else {
      if (!/^[a-zA-Z0-9]+@[a-z]+\.[a-z]+$/.test(this.state.email)) {
        alert("incorrect email!!");
      } else {
        $("input").prop("disabled", true);
        $("textarea").prop("disabled", true);

        emailjs.init("8qRbdZ4nnc38GEina");

        var templateParams = {
          name: this.state.name,
          email: this.state.email,
          message: this.state.msg,
        };
        emailjs
          .send("service_5kp2q4x", "template_vvtzs0i", templateParams)
          .then(
            (response) => {
              $("#successMSG").css({ display: "block" });
              $("#errorMSG").css({ display: "none" });
              $("section").css({
                opacity: "1",
              });
            },
            function (error) {
              $("#errorMSG").css({ display: "block" });
              $("#successMSG").css({ display: "none" });
            }
          );
      }
    }
  };

  ContactHandker = () => {
    $("section").css({
      opacity: "0.1",
    });

    this.setState({
      toggle: true,
    });
  };
  render() {
    return (
      <>
        <header className="">
          <BrowserRouter>
            <nav className="navbar navbar-expand-lg my-4 ">
              <div className="container-fluid nav-elements">
                <a className="navbar-brand" href="/">
                  iSmail aIt hSaine
                </a>
                <i
                  className="navbar-toggler uil uil-bars"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                ></i>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a
                        className="nav-link text-center"
                        aria-current="page"
                        href="/"
                      >
                        <i className="uil uil-home"></i> Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link  text-center"
                        aria-current="page"
                        href="#about-me"
                      >
                        <i className="uil uil-brain"></i> About Me
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-center "
                        aria-current="page"
                        href="#my-skills"
                      >
                        <i className="uil uil-book-reader"></i> My Skills
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link text-center "
                        aria-current="page"
                        href="#projects"
                      >
                        <i className="uil uil-book-reader"></i> My Projects
                      </a>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        onClick={this.ContactHandker}
                        className="nav-link  text-center"
                        id="contact"
                        aria-current="page"
                        to="/"
                      >
                        <i className="uil uil-at"></i> Contact me
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </BrowserRouter>
        </header>
        {this.state.toggle && (
          <div id="form-alert" className="">
            <button
              title="Close "
              id="closeIcon"
              onClick={() => {
                this.setState({ toggle: false });
                $("section").css({
                  opacity: 1,
                });
              }}
              className="btn btn-danger"
            >
              <i className="uil uil-multiply"></i>
            </button>
            <div
              style={{ display: "none" }}
              id="successMSG"
              className="alert alert-success m-2"
              role="alert"
            >
              Your message sent with success to ismail
            </div>
            <div
              style={{ display: "none" }}
              id="errorMSG"
              className="alert alert-danger m-2"
              role="alert"
            >
              Error : message doesn't sent to ismail!!
            </div>
            <form>
              <div className="row">
                <div className="col-12 col-md-6 my-3">
                  <label htmlFor="" className="form-label">
                    Your Name <span className="text-danger">*</span> :
                  </label>
                  <input
                    onChange={(e) => this.setState({ name: e.target.value })}
                    placeholder="write yout name..."
                    name="fullname"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 my-3">
                  <label htmlFor="" className="form-label">
                    Your Email <span className="text-danger">*</span> :
                  </label>
                  <input
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder="Write your email.."
                    name="email"
                    type="email"
                    className="form-control"
                  />
                </div>
                <div className="col-12 my-3">
                  <label className="form-label" name="msgcontent" htmlFor="">
                    Message <span className="text-danger">*</span> :{" "}
                  </label>
                  <textarea
                    onChange={(e) => this.setState({ msg: e.target.value })}
                    name="msg"
                    style={{ height: "100px", resize: "none" }}
                    className="form-control"
                    placeholder="write the message ..."
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <div className="my-4">
                  <button
                    id="sendMsg"
                    onClick={this.sendEmail}
                    type="button"
                    className="custom-btn"
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </>
    );
  }
}

export default Header;
