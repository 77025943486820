import React from "react";
import "./about.css";


class About extends React.Component {

    render() {
        return (
            <section id="about-me" className=" m-2">
                <h1 className="text-white my-4"><span className="h1 big-title">&#60;/&#62;</span> About me :</h1>
<hr className="text-white"/>
                <div id="content">
                    <div className="description"> 
                        <p style={{width:"90%",textAlign:"justify"}}>Hello My Name Is <span className="main-color">Ismail ait hsaine</span> I was born in the beautiful city of Tata on May 22, 2000. Currently, I am pursuing my studies at Specialized Institute of Applied Technology , where I am expanding my knowledge and skills in the field of fullstack development. In this presentation, I will share my journey as a developer, highlighting my educational background, projects, and aspirations.
                        </p>
                        <div className="About-boxes my-4 row">
                            <div className="col-md-5 col-lg-4 col-xl-3">Front-End Developper</div>
                            <div className="col-md-5 col-lg-4 col-xl-3">Back-End Developper</div>
                            <div className="col-md-5 col-lg-4 col-xl-3">Database Administrator</div>
                        </div>
                        
                    </div>
                    <div id="my-picture" className="">
                        <img alt="ismail ait hsaine" src="img/profile.png" />
                    </div>
                </div>
                <hr className="text-white my-5"/>
            </section>
        )
    }
}
export default About;